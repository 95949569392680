import logo from "./logo.svg"
import background from "./background.svg"
import "./App.css"

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>

      <p className="info">
        <div>JUBL install s.r.o.</div>
        <div>jurak@jubl-instal.cz</div>
        <div>+420 774 334 626</div>
      </p>
    </div>
  )
}

export default App
